(function ($) {
    class TopImage {
        opt = {
            ratio: .35
        };

        constructor(el, options) {
            this.$el = $(el);
            this.opt = $.extend(true, this.opt, options);

            this.setInitialBgPos();
            this.bindEvents();
        }

        bindEvents() {
            let $window = $(window);
            $window.bind('scroll', () => {
                let scrolled = $window.scrollTop();
                if (this.unit == '%' && this.initialPos + scrolled * this.opt.ratio < 100) {
                    this.$el.css('background-position-y', (this.initialPos + scrolled * this.opt.ratio / 2) + this.unit);
                }
            });
        }

        setInitialBgPos() {
            this.bgPos = this.$el.css("background-position-y");
            this.initialPos = 0;
            this.unit = 'px';


            if (this.bgPos.indexOf('%') > -1) {
                this.initialPos = parseInt(this.bgPos, 10);
                this.unit = "%";

                let maxPercPos = this.$el.height() + this.$el.offset().top;

                let diff = 100 - this.initialPos;
                this.opt.ratio = diff / maxPercPos;
            }
            else if (this.bgPos.indexOf('px') > -1) {
                this.initialPos = parseInt(this.bgPos, 10);
            }
        }
    }

    $.fn.topImage = function (options) {
        this.each(function () {
            new TopImage(this, options);
        });
        return this;
    };

    $(window).on('load', () => {
        $('.top-image').topImage();
    });

    $(() => {

        $("html").removeClass("no-js").addClass("js");
    });

})(jQuery);
